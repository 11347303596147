@import url("https://fonts.googleapis.com/css?family=Oswald|Rambla|Staatliches&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bungee&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bungee&family=Sofia+Sans+Condensed:ital,wght@0,1;0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,1;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");

:root {
    --color-black: #000000;
    --color-white: #ffffff;
    --color-border: #ffffff34;
    --font-staat: "Staatliches", cursive;
    --font-os: "Oswald", sans-serif;
    --font-ram: "Rambla", sans-serif;
    --modal-text-color: #818ba8;
    --modal-icon-color: #3f9891;
    --modal-icon-color-hover: #2c6661;
    --modal-text-color-primary: rgb(10, 25, 47);
}

* {
    font-family: ubuntu;
}

::-webkit-scrollbar-corner {
    background: transparent;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: var(--modal-icon-color);
    border-radius: 25px;
}

::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(-45deg, var(--modal-icon-color) 0%, var(--modal-icon-color-hover) 100%);
}

::-webkit-scrollbar-thumb:active {
    background: linear-gradient(-45deg, var(--modal-icon-color-hover) 100%, var(--modal-icon-color) 0%);
}

* {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    user-select: none;
}

body {
    margin: 0;
    padding: 0;
    display: flex;
    overflow-x: hidden !important;
    background-color: #0a192f;
}

::selection {
    background: #004f04;
    color: #fff;
}

#loader {
    border: 12px solid #f3f3f3;
    border-radius: 50%;
    border-top: 12px solid #444444;
    width: 70px;
    height: 70px;
    animation: spin 1s linear infinite;
}

.personalProjectsH1 {
    // Only on mobil
    @media (max-width: 768px) {
        line-height: 30px !important;
    }
}

.marqueeText {
    width: 100vw;
    position: absolute;
    font-size: 350px;
    font-weight: 600;
    color: white;
    font-family: "Bungee";
    animation: neon 30s infinite;
    height: fit-content;
    text-align: center;
    opacity: 0.1;
    text-transform: uppercase;
    padding: 0px;
    margin: 0px;
    bottom: 0px;
}

#myVideo {
    position: initial;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#certifications {
    img {
        height: 100%;
        /* width: 100%; */
        /* max-width: none !important; */
        max-height: 125px;
        min-width: auto;
        max-width: 100%;
    }
    h6 {
        font-style: italic;
        font-size: 0.8rem;
    }
}

.certifications {
    color: white !important;
}

.bannerRow {
    // No wrap
    flex-wrap: nowrap !important;
    width: 100vw;
    overflow-x: hidden !important;
}

.mainBannerClass {
    width: 100%;
}

.title_loader-mobile {
    font-size: 20px;
    color: white;
    position: relative;
    width: fit-content;
    span {
        mix-blend-mode: difference;
    }
    h1,
    i {
        font-family: bungee;
        font-size: 30px;
    }
}

a {
    text-decoration: none;
    color: inherit;
}

.achievement-text {
    line-height: 0;
    margin-top: 10px;
}

.center {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.bio {
    text-align: justify;
}

.font-staat {
    font: normal 400 18px var(--font-staat);
}

.font-os {
    font: normal 300 18px var(--font-os);
}

.font-ram {
    font: normal bold 18px var(--font-ram);
}

.font-rami {
    font: normal bold 10px var(--font-rami);
}

.font-size-40 {
    font-size: 40px;
}

.font-size-34 {
    font-size: 34px;
}

.font-size-27 {
    font-size: 27px;
}

.font-size-20 {
    font-size: 20px;
}

.font-size-16 {
    font-size: 16px;
}

.bgcolor-black {
    background-color: var(--color-black);
}

#header {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    transition: left 0.5s ease;
    nav {
        height: 100vh;
    }
    .site-title .navbar-brand {
        letter-spacing: 2px;
        color: var(--color-white);
    }
    .nav-link {
        margin: 0.4rem 1rem;
        border-bottom: 1px solid var(--color-border);
        text-transform: uppercase;
        &:hover {
            color: var(--color-white) !important;
        }
    }
    .toggle-button {
        position: fixed;
        overflow: hidden;
        top: 25px;
        right: 20px;
    }
}

.posFixed {
    position: fixed;
}

.header_title {
    font-family: "Pacifico";
    color: white;
    animation: neon 30s infinite;
}

.toggle-left {
    left: 0 !important;
    width: 1000px !important;
}

.ico {
    padding: 2px;
}

.sk {
    color: #7e7d7d;
    margin: 10px;
}

.filter_img {
    filter: blur(20px);
}

.tilt:hover {
    transform: scale(0.9);
    transition: 0.5s;
    filter: grayscale(50%);
}

.mobile-width {
    width: 380px !important;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}

.accordion-collapse {
    background-color: var(--color-black);
    color: var(--color-white);
}

.collapse {
    background-color: var(--color-black);
    color: var(--color-white);
}

.education_box {
    height: 275px;
    overflow: hidden;
    cursor: pointer;
    :hover {
        background: #f3f3f3;
    }
}

.panel {
    border: none;
    height: 100%;
    overflow: hidden;
}

.experience_box {
    height: 330px;
    overflow: hidden;
    cursor: pointer;
    :hover {
        background: #f3f3f3;
    }
}

.mobileBanner {
    background-color: rgba(0, 0, 0, 0.79);
    background-size: cover;
    width: 100%;
    height: calc(100vh - 57px) !important;
    // max-height: 980px;
    filter: blur(0px);
    position: relative;
    overflow: hidden;
}

.mobile-banner {
    height: auto !important;
}

.menu_backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    transition: 0.5s;
    // display: none;
}

/* Slideshow container */
.slideshow-container {
    // max-width: 1000px;
    position: relative;
    margin: auto;
    width: auto;
    height: 100vh !important;
    // Filter to darken the images
    .imgCarousal {
        filter: brightness(20%);
        width: auto;
        height: 100vh;
    }

    /* Hide the images by default */
    .mySlides {
        display: none;
    }

    /* Caption text */
    .text {
        color: #ffffff;
        font-size: 1.25em;
        padding: 4px 25px;
        position: absolute;
        bottom: 25px;
        width: fit-content;
        text-align: left;
        background: rgba(255, 255, 255, 0);
        // border: 2px solid #ffffff;
        border-radius: 25px;
        left: 5px;
        // transform: translate(-50%, 0);
    }

    /* Number text (1/3 etc) */
    .numbertext {
        color: #f2f2f2;
        font-size: 12px;
        padding: 8px 12px;
        position: absolute;
        top: 0;
    }

    /* Fading animation */
    .fade {
        animation-name: fade;
        animation-duration: 5s;
    }

    @keyframes fade {
        0% {
            opacity: 0;
        }
        10% {
            opacity: 1;
        }
        90% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
}

.site-banner {
    .contact_reach {
        svg {
            &:hover {
                transform: scale(1.2);
                transition: 0.5s;
                z-index: 2;
                path {
                    color: #c7c7c7 !important;
                }
            }
        }
    }
    .contact_reach {
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
    }
    svg:first-child {
        margin-left: 15px;
    }
    path {
        font-size: 2rem;
        color: rgb(255, 255, 255) !important;
        margin: 10px;
    }
    .mobile {
        height: fit-content;
        .banner-area {
            // background-color: rgba(0, 0, 0, 0.79);
            background-size: cover;
            width: 100%;
            height: calc(100vh - 57px) !important;
            filter: blur(0px);
            position: relative;
            overflow: hidden;
        }
    }

    .banner-area {
        background-color: #0a192f;
        background-size: cover;
        width: 100%;
        height: 100vh;
        // max-height: 980px;
        filter: blur(0px);
        position: relative;
        overflow: hidden;
    }
    .banner-area {
        .grayScale_vid_bg {
            filter: grayscale(100%) blur(0px);
            opacity: 55%;
        }
        .colored_vid_bg {
            filter: grayscale(0%);
        }
        .contact_react_mobile_top {
            position: absolute;
            bottom: 91% !important;
            transition: 0.5s;
            z-index: 4;
        }
        .contact_reach_mobile {
            margin-top: 20px;
            margin-bottom: 20px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            transition: 0.5s;
            position: absolute;
            bottom: 0;
        }
        .mobile_menu_open {
            width: 170px !important;
            top: 50% !important;
            transform: translate(0, -50%);
            background-color: rgb(10, 25, 47) !important;
            border: 1px solid white;
            box-shadow: inset 10px 20px 0px 2px rgba(255, 255, 255, 0.5) !important;
        }
        .mobile_menu_open_bottom {
            transform: translate(-50%, 0%) !important;
            height: 405px !important;
            transition: 0.5s;
            width: 95vw !important;

            // For bottom Menu
            background-color: var(--modal-icon-color-hover) !important;
            border: 1px solid white;
            .mobile_menu_item {
                display: flex !important;
            }
            .mobile_menu_bottom_line {
                // Add a line
                width: 25% !important;
                transition: 0.5s;
                height: 5px;
                background-color: white !important;
                // background-color: red;
                margin: auto;
                margin-top: 10px !important;
                margin-bottom: 10px;
                border-radius: 10px;
            }
            // box-shadow: inset 10px 20px 0px 2px rgba(255, 255, 255, 0.5) !important;
        }
        .mobile_menu_bottom {
            // From Bottom
            position: absolute;
            right: 50%;
            left: 50%;
            transform: translate(-50%, 95%);
            // margin: auto;
            width: 35vw;
            height: 30%;
            // box-shadow: inset 0px 0 0 83px rgba(255, 255, 255, 0.5);
            // Make the div stay at bottom

            bottom: 0 !important;
            z-index: 2;
            background-color: white;
            border-top-left-radius: 25px;
            border-top-right-radius: 25px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            transition: 0.5s;
            padding: 0 5px;
            overflow: hidden;
            // transform: translate(-50%, 0);
            .mobile_menu_bottom_line {
                // Add a line
                width: 50%;
                height: 5px;
                background-color: var(--modal-icon-color-hover);
                // background-color: red;
                margin: auto;
                margin-top: 5px;
                margin-bottom: 10px;
                border-radius: 10px;
            }
            .mobile_menu_item {
                display: none;
                // display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                align-content: center;
                align-items: center;
                padding: 5px;
                position: absolute;
                bottom: 15px;
                // Format menu items
                a {
                    text-align: right;
                    width: 100%;
                    text-decoration: none;
                    text-align: -webkit-right;

                    // For bottom menu
                    text-align: -webkit-left;
                    p {
                        color: black;
                        color: white;
                        font-size: 20px;
                        font-weight: bold;
                        margin: 5px;
                        width: fit-content;
                        transition: 0.5s;
                        &:hover {
                            color: #777777;
                            border-bottom: 1px solid #c7c7c7;
                        }
                    }
                }
            }
        }
        .mobile_menu {
            top: 50%;
            right: 0;
            z-index: 2;
            width: 5px;
            height: 54vh;
            background-color: white;
            border-top-left-radius: 500px;
            border-top-right-radius: 0px;
            border-bottom-left-radius: 500px;
            border-bottom-right-radius: 0px;
            transition: 0.5s;
            padding: 50px 0;
            transform: translate(0, -50%);

            // From Bottom
            position: absolute;
            /* top: 50%; */
            /* right: 0; */
            z-index: 2;
            background-color: white;
            box-shadow: inset 0px 0 0 83px rgba(255, 255, 255, 0.5);
            transition: 0.5s;
            /* padding: 50px 0; */
            .mobile_menu_item {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                align-content: center;
                align-items: center;
                padding: 10px;
                // Format menu items
                a {
                    text-align: right;
                    width: 100%;
                    text-decoration: none;
                    text-align: -webkit-right;

                    // For bottom menu
                    // text-align: -webkit-center;
                    p {
                        color: black;
                        color: white;
                        font-size: 20px;
                        font-weight: bold;
                        margin: 5px;
                        width: fit-content;
                        transition: 0.5s;
                        &:hover {
                            color: #777777;
                            border-bottom: 1px solid #c7c7c7;
                        }
                    }
                }
            }
        }
        .mobile_author {
            // left: 0% !important;
            margin-left: 0px !important;
            height: 100% !important;
            top: 0% !important;
            transform: translate(-50%, 0%) !important;
            display: flex;
            flex-direction: column;
            // flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            width: 100% !important;
            align-content: center;
            // Hamburger Menu
            .hamburger_menu {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                align-content: center;
                position: absolute;
                cursor: pointer;
                top: 0;
                right: 0;
                margin: 10px;
                z-index: 7;
                width: 40px;
                transition: 0.5s;
                // Transparent Glass background effect
                background-color: rgba(255, 255, 255, 0.5);
                padding: 7px;
                border-radius: 10px;
                border-top-right-radius: 0px;
                .hamburger_menu_line {
                    width: 100%;
                    height: 2px;
                    background-color: rgb(0, 0, 0);
                    margin: 4px 0;
                    transition: 0.4s;
                }
                &:focus,
                &:hover,
                &:active {
                    transform: scale(1.2);
                    z-index: 7;
                }
                .hamburger_menu_line_cross_1 {
                    transform: rotate(45deg) translate(-4px, -6px);
                    width: 100%;
                    height: 2px;
                    background-color: rgb(0, 0, 0);
                    margin: 4px 0;
                    transition: 0.4s;
                }
                .hamburger_menu_line_cross_2 {
                    transform: rotate(-45deg) translate(-2px, 3px);
                    width: 100%;
                    height: 2px;
                    background-color: rgb(0, 0, 0);
                    margin: 4px 0;
                    transition: 0.4s;
                }
            }
        }
        .author {
            // Make everything in the author section in the center
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            position: absolute;
            top: 50%;
            left: 50%;
            right: 50%;
            transform: translate(-50%, 50%);
            bottom: 50%;
            width: 70vw;
            flex-wrap: nowrap;
            flex-direction: row;
            // Make the author section in the center

            // position: absolute;
            // top: 10%;
            // left: 5%;
            // height: 320px;
            // width: 100%;
            // margin-left: 25px;
            .nomobile-text {
                width: -moz-fit-content;
                width: fit-content;
                color: white;
                /* position: absolute; */
                /* top: 50%; */
                right: 0;
                /* transform: translate(10%, -50%); */
                /* margin-left: 30%; */
                text-align: left;
                margin-left: 10px;
                /* padding: 10px;*/
                /* padding: 10px; */
                .hello {
                    margin-top: 0px;
                    margin-bottom: 0px;
                    font-size: 2rem;
                    font-weight: 600;
                }
                .iam {
                    line-height: 25px;
                    color: rgb(187, 187, 187);
                    font-size: 1.2rem;
                    font-weight: 600;
                    text-indent: 5px;
                }
                .name {
                    width: fit-content;
                    line-height: 30px;
                    font-size: 3rem;
                    font-weight: 600;
                    padding-left: 10px;
                    padding-right: 10px;
                    background-color: #6c6c6c;
                }
                .designation {
                    text-indent: 10px;
                    line-height: 2rem;
                    font-size: 20px;
                    font-weight: 600;
                }
            }
            .scramble_text-mobile {
                color: white;
                font-family: "Pacifico";
                animation: neon 30s infinite;
            }

            .author-img {
                /* margin: auto; */
                height: 350px;
                /* float: left; */
                width: -moz-fit-content;
                /* width: fit-content; */
                /* position: absolute; */
                left: 0;
                /* transform: translate(10%, 0%); */
                /* top: 0; */
                /* bottom: 0; */
                /* margin: auto; */
                width: 250px;
                margin-right: 10px;

                .grayscaleFilter {
                    // Hue rotate to blue color
                    filter: hue-rotate(00deg) brightness(100%) contrast(100%);

                    &:hover {
                        animation: huechange 2s infinite ease-in-out;
                    }
                }

                .mobile_img {
                    width: 65%;
                    // filter: hue-rotate(180deg) brightness(-1%) contrast(200%);
                }

                .normal_img {
                    width: 100%;
                    height: 100%;
                    border: 2px solid white;
                    border-radius: 50%;
                }
            }
        }
    }
}

.skill .bars p,
.site-content .skill .bars span {
    line-height: 10px;
}

#footer form .row .col input[type="text"],
#footer form .row .col input[type="email"],
#footer form textarea {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid gray;
    padding: 1.5rem 1rem;
}

.about_me_icon {
    width: 350px;
}

.container {
    width: 1200px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: 15px;
    margin: 0 auto;
}
.container .card {
    position: relative;
    width: 300px;
    height: 400px;
    margin: 0 auto;
    background: #fff;
    box-shadow: 0 15px 60px rgba(0, 0, 0, 0.5);
}

.container .card .face {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container .card .face .face1 {
    box-sizing: border-box;
    padding: 20px;
}

.container .card .face .face1 h2 {
    margin: 0;
    padding: 0;
}

.container .card .face .face2 {
    background: #111;
    transition: 0.5s;
}

.container .card .face .face2 h2 {
    margin: 0;
    padding: 0;
    font: 10em;
    color: #fff;
    transition: 0.5s;
}

.list-group-item {
    margin-top: 25px;
    display: flexbox;
}

.back_btn {
    display: inline;
    margin: 20px;
}

#userRepos li li {
    transition: 0.3s;
}

#userRepos li li:hover {
    background-color: #f1efef;
}

.carousal1 {
    width: calc(100vw - 35px);
    height: fit-content;
    li:first-child {
        margin-left: 10px;
    }
    li {
        margin-left: 5px;
        margin-right: 25px;
        width: fit-content !important;
    }
    .card {
        max-height: 300px;
        min-height: 300px;
        width: 300px;
        box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.5);

        .card-title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .card-text {
            font-size: 0.75rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &:hover {
            background-color: #eaeaea27;
        }
    }
    .tags-section {
        .tags-badge {
            margin-top: 5px;
            background-color: #f1efef;
            border-radius: 5px;
            padding: 5px;
            margin-right: 5px;
            color: #111;
        }
    }
    .read_more_btn {
        transition: 1s;
        margin-top: 5px;
        background-color: #111;
        border-radius: 5px;
        padding: 5px;
        border: none;
        margin-right: 5px;
        bottom: 30px;
        color: #f1efef;
        position: fixed;
        &:hover {
            transition: cubic-bezier(1, 0, 0, 1);
            background-color: #f1efef;
            color: #111;
            border: 1px solid #111;
        }
    }
}

.carousal_education {
    width: calc(100vw - 35px);
    height: fit-content;
    li:first-child {
        margin-left: 10px;
    }
    li {
        margin-left: 5px;
        margin-right: 25px;
        width: fit-content !important;
    }
    .card {
        max-height: 300px;
        min-height: 300px;
        width: 300px;
        .card-title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .card-text {
            font-size: 0.75rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &:hover {
            background-color: #eaeaea27;
        }
    }
    .tags-section {
        .tags-badge {
            margin-top: 5px;
            background-color: #f1efef;
            border-radius: 5px;
            padding: 5px;
            margin-right: 5px;
            color: #111;
        }
    }
    .read_more_btn {
        transition: 1s;
        margin-top: 5px;
        background-color: #111;
        border-radius: 5px;
        padding: 5px;
        border: none;
        margin-right: 5px;
        bottom: 30px;
        color: #f1efef;
        position: fixed;
        &:hover {
            transition: cubic-bezier(1, 0, 0, 1);
            background-color: #f1efef;
            color: #111;
            border: 1px solid #111;
        }
    }
}

.scrollTop {
    position: fixed;
    bottom: -100px;
    right: 40px;
    width: 40px;
    height: 40px;
    // background: #ffffff url(assets/misc/up.png);
    border-radius: 50%;
    background-size: 40px;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 50;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: 0.5s;
}

.scrollTop.active {
    bottom: 40px;
    visibility: visible;
    opacity: 1;
}

.texter {
    font-weight: 100;
    font-size: 28px;
    color: #fafafa;
}

.full-height {
    max-height: 100vh;
    min-height: 80vh;
}

.retro {
    -webkit-text-stroke: 1px #000000;
    text-shadow: 3px 3px 0px #000000;

    font-family: "Bungee", cursive;
    color: #ffffff;
    font-size: 2rem;

    text-transform: uppercase;
}

.carousal_img_achievements {
    width: 120px;
    height: auto;
}

#contact_us {
    .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
    }
    .contact_reach {
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
    }
    svg:first-child {
        margin-left: 15px;
    }
    .fab {
        font-size: 2rem;
        color: #111;
        margin: 10px;
        &:hover {
            color: #f1efef;
        }
    }
}

.check_achievements {
    filter: invert(23%) sepia(6%) saturate(12%) hue-rotate(328deg) brightness(96%) contrast(85%);
}

.iframe_div {
    height: 100%;
    width: 100%;
    iframe {
        height: 100%;
        width: 100%;
        border: none;
        opacity: 0.2;
        transition: 0.5s;
        overflow: hidden;
    }
}

.skills_row {
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    align-items: center;
    align-content: center;
    justify-content: space-evenly;
}

.styleSkill {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #f1efef;
    font-size: 1.5rem;
    font-weight: 700;
    color: #111;
    transition: 0.5s;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    transition: 0.5s;
    margin: 5px;
    .skill_indi {
        width: 60px;
        height: 60px;
        position: absolute;
        margin: 0px 11px 0 0px !important;
        &:hover {
            animation-duration: 1s;
            animation-iteration-count: initial;
            animation-name: icon_color;
        }
    }
}

.projectsSectionDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .tech_stack {
        position: relative;
        bottom: 0;
        right: 10px;
        margin: 0px 11px 10px 0px !important;
        width: 100%;
        text-align: right;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;
    }
    svg {
        width: 40px !important;
        height: 25px;
        margin: 0px !important;
        path {
            color: var(--modal-text-color-primary) !important;
        }
        &:hover {
            animation-duration: 1s;
            animation-iteration-count: initial;
            animation-name: icon_color;
        }
    }
    p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .projectCardButtons {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
        margin-top: 10px;
        button {
            background-color: var(--modal-text-color-primary);
            margin: 5px;
            font-size: 12px;
            color: white;
            // min-width: 100px;
            &:hover {
                background-color: var(--modal-icon-color-hover);
                transition: 0.5s;
            }
        }
    }
}

@keyframes spotlight {
    0% {
        filter: brightness(100%);
    }
    50% {
        filter: brightness(200%);
    }
    100% {
        filter: brightness(101%);
    }
}

@keyframes huechange {
    0% {
        filter: hue-rotate(0deg) brightness(100%) contrast(100%);
    }
    50% {
        filter: hue-rotate(200deg) brightness(100%) contrast(150%);
    }
    100% {
        filter: hue-rotate(0deg) brightness(100%) contrast(100%);
    }
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}

.skills_progress {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: stretch;
    width: 100%;
    span {
        width: 60px;
    }
    .progress_bar {
        display: -ms-flexbox;
        display: flex;
        height: 4rem;
        overflow: hidden;
        font-size: 0.75rem;
        background-color: #e9ecef;
        border-radius: 0.25rem;
    }

    .progress-box {
        width: 100%;
        margin-bottom: 20px;
    }
}

.padding_bottom_skillset {
    padding-bottom: 20px;
}

@keyframes animate {
    0%,
    100% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(175px);
    }
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes neon {
    10%,
    30%,
    50%,
    70%,
    90% {
        text-shadow:
            white 0 0 12px,
            #6a6a6a 0 0 24px,
            #6a6a6a 0 0 36px;
    }

    0%,
    20%,
    40%,
    60%,
    80%,
    100% {
        text-shadow: none;
    }
}

@keyframes icon_color {
    50% {
        color: #2b2b2b;
    }
}

@keyframes jitter {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(15deg);
    }
    75% {
        transform: rotate(-15deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes shadow {
    0% {
        box-shadow: 2px 2px 100px rgb(0, 0, 0);
    }
    25% {
        box-shadow: 2px 2px 10px rgb(255, 255, 255);
    }
    50% {
        box-shadow: 2px 2px 110px rgb(0, 0, 0);
    }
    100% {
        box-shadow: 2px 2px 100px rgb(255, 255, 255);
    }
}

@keyframes flicker {
    0% {
        border-color: rgb(8, 95, 0);
    }
    50% {
        border: 4px solid rgb(288, 233, 228);
    }
    100% {
        border-color: rgb(8, 95, 0);
    }
}

@keyframes float {
    0% {
        -webkit-transform: translate(0, 0px);
    }
    50% {
        -webkit-transform: translate(0px, 20px);
    }
    100% {
        -webkit-transform: translate(0, -0px);
    }
}

@keyframes project_color {
    50% {
        background-color: #f1efef;
    }
}

/* Popup style */
.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    animation: fadeIn 0.5s;
    transition: 0.5s;
    z-index: 999;
    transform: translate(0);
}

.hide {
    transform: translateY(-100%) !important;
    .box {
        width: 0px !important;
    }
}

@keyframes fadeIn {
    0% {
        // Appear from top
        // transform: translateY(-100%);

        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.box {
    position: relative;
    width: -moz-fit-content;
    width: 80%;
    margin: auto;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow-y: scroll;
    overflow-x: hidden;
    height: fit-content;
    max-height: 80%;
    background: rgba(10, 25, 47, 0.8);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    transition: 0.5s;
}

.close-icon {
    cursor: pointer;
    position: fixed;
    right: 20px;
    top: 10px;
    z-index: 999;
    color: var(--modal-icon-color);
    &:hover {
        transform: scale(1.2);
        transition: 0.5s;
        path {
            color: #c7c7c7 !important;
        }
    }
}

.inPopUp {
    .retro {
        -webkit-text-stroke: 1px var(--modal-text-color-primary);
        text-shadow: 3px 3px 0px var(--modal-text-color-primary);
        font-family: "Bungee", cursive;
        color: var(--modal-icon-color);
        font-size: 2rem;
        text-transform: uppercase;
    }

    .about {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        height: 100%;
        color: #fff !important;
        width: 100%;
        .about_me_icon {
            position: absolute;
            bottom: 0;
            right: -25px;
            width: 450px;
            opacity: 0.1;
            filter: sepia();
        }
        .contact {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            align-content: center;
            height: 100%;
            width: 100%;
            p {
                margin: 20px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: center;
            }
            path {
                font-size: 2rem;
                color: var(--modal-icon-color) !important;
                margin: 10px;
            }
        }
    }

    .skill {
        .CircularProgressbar-trail {
            stroke: var(--modal-icon-color) !important;
            opacity: 0.3;
        }
        .CircularProgressbar-path {
            stroke: var(--modal-icon-color) !important;
        }
        svg {
            &:first-child {
                margin-left: 0 !important;
            }
        }
        .skills_row {
            flex-wrap: wrap;
            margin-right: -15px;
            margin-left: -15px;
            align-items: center;
            align-content: center;
            justify-content: center;
            justify-content: space-evenly;
        }

        .styleSkill {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            background-color: #f1efef00;
            font-size: 1.5rem;
            font-weight: 700;
            color: #111;
            transition: 0.5s;
            flex-direction: column;
            flex-wrap: nowrap;
            align-content: center;
            transition: 0.5s;
            margin: 5px;
            .skill_indi {
                width: 60px;
                height: 60px;
                position: absolute;
                margin: 0 !important;
            }
        }
        .skills_progress {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: flex-start;
            align-items: stretch;
            width: 100%;
            span {
                width: 60px;
            }
            .progress_bar {
                display: -ms-flexbox;
                display: flex;
                height: 4rem;
                overflow: hidden;
                font-size: 0.75rem;
                background-color: #e9ecef;
                border-radius: 0.25rem;
            }

            .progress-box {
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }

    .experience_box {
        display: flex;
        color: white;
        height: 175px;
        overflow: hidden;
        cursor: pointer;
        :hover {
            background: none !important;
        }

        .indexNumber {
            position: absolute !important;
            top: -80px;
            left: 0 !important;
            font-size: 177px !important;
            z-index: 1 !important;
            height: -moz-fit-content !important;
            height: fit-content !important;
            height: 8px !important;
            color: var(--modal-icon-color) !important;
            opacity: 0.2;
        }

        .iconExperience {
            position: absolute !important;
            top: -50px;
            right: -120px !important;
            font-size: 117px !important;
            z-index: 1 !important;
            height: -moz-fit-content !important;
            height: fit-content !important;
            height: 8px !important;
            color: var(--modal-icon-color) !important;
            opacity: 0.1;
            visibility: hidden;
        }

        .panel-body {
            z-index: 2;
            display: flex;
            width: 100%;
            flex-direction: column;
            border-bottom: 1px solid var(--modal-icon-color);
            padding-bottom: 20px;
            &:hover {
                border-bottom: 1px solid var(--modal-icon-color);
                border-bottom-width: 3px;
                transition: 0.5s;
                background: linear-gradient(var(--modal-icon-color), var(--modal-icon-color-hover)) 0 30% no-repeat !important;
                transform: scale(1.1);
                transition: 0.5s;
                color: var(--modal-text-color-primary) !important;
                padding: 15px 0 0 0 !important;
                border-top-left-radius: 0 !important;
                border-top-right-radius: 0 !important;
                border-bottom-left-radius: 20% 50% !important;
                border-bottom-right-radius: 0 !important;

                .iconExperience {
                    visibility: visible;
                }
                .indexNumber {
                    color: white !important;
                    text-shadow:
                        0 0 10px var(--modal-icon-color),
                        0 0 20px var(--modal-icon-color),
                        0 0 30px var(--modal-icon-color),
                        0 0 40px var(--modal-icon-color),
                        0 0 50px var(--modal-icon-color),
                        0 0 60px var(--modal-icon-color),
                        0 0 70px var(--modal-icon-color);
                }
            }
            h3,
            p {
                z-index: 2;
            }
            p {
                width: 70%;
                place-self: center;
                justify-self: stretch;
                font-size: 0.8rem;
            }
        }
    }

    .projectsSectionDiv {
        .projectCard {
            box-shadow: 10px 10px 0 0 var(--modal-icon-color);
            transition: 0.5s;
            &:hover {
                transition: 0.5s;
                box-shadow: 10px 10px 0 0 var(--color-border);
            }
        }
    }

    .achievement-text {
        color: white !important;
    }

    .writeups {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        border: 0;
        border-radius: 0;
        .row {
            span {
                width: fit-content !important;
            }
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
        }

        .imgContainer {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            height: 45%;
            overflow: hidden;
            width: 100%;

            img {
                width: 100%;
            }
        }
        .writeUpCard {
            height: 320px;
            border: 0;
            border-radius: 10px;
            min-height: 320px;
            box-shadow: 10px 10px 0 0 var(--modal-icon-color);
            transition: 0.5s;
            &:hover {
                box-shadow: 0 0 0 0;
                .readMoreSpan {
                    visibility: visible;
                }
                .imgContainer {
                    img {
                        // Zoom
                        transition: transform 0.2s;
                        transform: scale(1.1);
                    }
                }
            }
            .readMoreSpan {
                visibility: hidden;
                display: flex;
                place-content: center;
                position: absolute;
                width: 100%;
                left: 0;
                bottom: 0;
                opacity: 0.8;
                border: 0;
                border-radius: 0;
                font-family: "Staatliches";
                line-height: 45px;
                background-color: var(--modal-icon-color);
                color: var(--color-black) !important;
                height: 100%;
                padding: 50%;
                font-size: 50px;
                cursor: pointer;
            }
        }

        .card {
            width: 260px;
            margin: 10px;
            .card-body {
                padding: 5px !important;
            }
        }
    }

    .research {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        border: 0;
        border-radius: 0;
        flex-direction: column;
        .imgContainer {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            height: 45%;
            overflow: hidden;
            width: 100%;
            img {
                width: 100%;
            }
        }

        .row {
            span {
                width: fit-content !important;
            }
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
        }
        .researchCard {
            background-color: var(--modal-icon-color);
            height: 320px;
            border: 0;
            border-radius: 10px;
            min-height: 320px;
            box-shadow: 10px 10px 0 0 var(--modal-icon-color-hover);
            transition: 0.5s;
            color: white;
            &:hover {
                box-shadow: 0 0 0 0;
                .readMoreSpan {
                    visibility: visible;
                }
                .imgContainer {
                    img {
                        // Zoom
                        transition: transform 0.2s;
                        transform: scale(1.1);
                    }
                }
            }
            .readMoreSpan {
                visibility: hidden;
                display: flex;
                place-content: center;
                position: absolute;
                width: 100%;
                left: 0;
                bottom: 0;
                opacity: 0.8;
                border: 0;
                border-radius: 0;
                font-family: "Staatliches";
                line-height: 45px;
                background-color: var(--modal-icon-color);
                color: var(--color-black) !important;
                height: 100%;
                padding: 50%;
                font-size: 50px;
                cursor: pointer;
            }
        }

        .card {
            width: 260px;
            margin: 10px;
            .card-body {
                padding: 5px !important;
            }
            .card-title {
                font-size: 1rem;
                margin-bottom: 0px;
                font-weight: bold;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
            .card-text {
                font-style: italic;
                font-size: 0.8rem;
                margin-bottom: 2px;
            }
            .authorContainer {
                display: flex;
                flex-direction: column;
                .authors {
                    display: flex;
                    flex-wrap: wrap;
                    font-size: 0.8rem;
                    margin: auto;
                    justify-content: center;
                    p {
                        font-size: 0.6rem;
                        margin-left: 7px;
                        border-radius: 10px;
                        padding: 2px 3px;
                        color: var(--modal-icon-color-hover);
                        background-color: var(--color-white) !important;
                        margin-bottom: 5px !important;
                    }
                }
            }
            .authorsName {
                margin-bottom: 0px !important;
            }
            .date {
                position: absolute;
                bottom: 5px;
                left: 50%;
                transform: translate(-50%, 0);
                font-size: 0.7rem;
                color: var(--color-white);
            }
        }
    }
}

.inMobilePopUp {
    .col-sm-12 {
        padding: 0 !important;
    }
    .row {
        width: 100%;
    }
    .close-icon {
        cursor: pointer;
        position: fixed;
        right: 6px !important;
        top: 10px;
        z-index: 999;
        color: var(--modal-icon-color);
        &:hover {
            transform: scale(1.2);
            transition: 0.5s;
            path {
                color: #c7c7c7 !important;
            }
        }
    }

    .retro {
        -webkit-text-stroke: 1px var(--modal-text-color-primary);
        text-shadow: 3px 3px 0px var(--modal-text-color-primary);
        font-family: "Bungee", cursive;
        color: var(--modal-icon-color);
        text-transform: uppercase;
    }

    .about {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        height: 100%;
        color: #fff !important;
        width: 100%;
        padding: 0px !important;
        .bio {
            font: normal 15px var(--font-ram) !important;
        }
        .retro {
            font-size: 1.3rem;
        }
        .about_me_icon {
            position: absolute;
            bottom: 0;
            right: -25px;
            // width: 450px;
            opacity: 0.1;
            filter: sepia();
        }
        .contact {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            align-content: flex-start;
            height: 100%;
            width: 100%;
            flex-wrap: wrap;
            .font-ram {
                margin: 5px;
                font: normal 13px var(--font-ram);
            }
            p {
                margin: 20px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: center;
            }
            svg:first-child {
                margin-left: 0px !important;
            }
            path {
                font-size: 2rem;
                color: var(--modal-icon-color) !important;
                // margin: 10px;
            }
        }
    }

    .skill {
        .ability {
            padding: 0px !important;
        }
        .retro {
            font-size: 2rem;
        }
        padding: 0 !important;
        width: 100%;
        .CircularProgressbar-trail {
            stroke: var(--modal-icon-color) !important;
            opacity: 0.3;
        }
        .CircularProgressbar-path {
            stroke: var(--modal-icon-color) !important;
        }
        svg {
            &:first-child {
                margin-left: 0 !important;
            }
        }
        .skills_row {
            flex-wrap: wrap;
            margin-right: -15px;
            margin-left: -15px;
            align-items: center;
            align-content: center;
            justify-content: center;
            justify-content: space-evenly;
            margin-left: 8px;
        }

        .styleSkill {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: #f1efef00;
            font-size: 1.5rem;
            font-weight: 700;
            color: #111;
            transition: 0.5s;
            flex-direction: column;
            flex-wrap: nowrap;
            align-content: center;
            transition: 0.5s;
            margin: 5px;
            .skill_indi {
                width: 25px;
                height: 25px;
                position: absolute;
                margin: auto !important;
                &:hover {
                    animation-duration: 1s;
                    animation-iteration-count: initial;
                    animation-name: icon_color;
                }
            }
        }
        .skills_progress {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: flex-start;
            align-items: stretch;
            width: 100%;
            span {
                width: 60px;
            }
            .progress_bar {
                display: -ms-flexbox;
                display: flex;
                height: 4rem;
                overflow: hidden;
                font-size: 0.75rem;
                background-color: #e9ecef;
                border-radius: 0.25rem;
            }

            .progress-box {
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }

    .reference {
        text-align: -webkit-center;
        padding-top: 0px !important;
        .retro {
            padding-top: 0px !important;
            // height: fit-content;
            // font-size: 2rem;
        }
        .experience_box {
            display: flex;
            color: white;
            height: fit-content;
            overflow: hidden;
            cursor: pointer;
            :hover {
                background: none !important;
            }

            .indexNumber {
                position: absolute !important;
                top: -80px;
                left: 0 !important;
                font-size: 177px !important;
                z-index: 1 !important;
                height: -moz-fit-content !important;
                height: fit-content !important;
                height: 8px !important;
                color: var(--modal-icon-color) !important;
                opacity: 0.2;
            }

            .iconExperience {
                position: absolute !important;
                top: -50px;
                right: -120px !important;
                font-size: 117px !important;
                z-index: 1 !important;
                height: -moz-fit-content !important;
                height: fit-content !important;
                height: 8px !important;
                color: var(--modal-icon-color) !important;
                opacity: 0.1;
                visibility: hidden;
            }

            .panel-body {
                z-index: 2;
                display: flex;
                width: 100%;
                flex-direction: column;
                border-bottom: 1px solid var(--modal-icon-color);
                padding-bottom: 20px;
                &:hover {
                    border-bottom: 1px solid var(--modal-icon-color);
                    border-bottom-width: 3px;
                    transition: 0.5s;
                    background: linear-gradient(var(--modal-icon-color), var(--modal-icon-color-hover)) 0 30% no-repeat !important;
                    transform: scale(1.1);
                    transition: 0.5s;
                    color: var(--modal-text-color-primary) !important;
                    padding: 15px 0 0 0 !important;
                    border-top-left-radius: 0 !important;
                    border-top-right-radius: 0 !important;
                    border-bottom-left-radius: 20% 50% !important;
                    border-bottom-right-radius: 0 !important;

                    .iconExperience {
                        visibility: visible;
                    }
                    .indexNumber {
                        color: white !important;
                        text-shadow:
                            0 0 10px var(--modal-icon-color),
                            0 0 20px var(--modal-icon-color),
                            0 0 30px var(--modal-icon-color),
                            0 0 40px var(--modal-icon-color),
                            0 0 50px var(--modal-icon-color),
                            0 0 60px var(--modal-icon-color),
                            0 0 70px var(--modal-icon-color);
                    }
                }
                h3,
                p {
                    z-index: 2;
                }
                p {
                    width: 70%;
                    place-self: center;
                    justify-self: stretch;
                    font-size: 0.8rem;
                }
            }
        }
    }

    .projectsSectionDiv {
        .retro {
            font-size: 2rem !important;
        }
        .projectCard {
            box-shadow: 10px 10px 0 0 var(--modal-icon-color);
            transition: 0.5s;
            &:hover {
                transition: 0.5s;
                box-shadow:
                    0 0 2px var(--modal-icon-color),
                    0 0 4px var(--modal-icon-color),
                    0 0 6px var(--modal-icon-color),
                    0 0 8px var(--modal-icon-color),
                    0 0 10px var(--modal-icon-color),
                    0 0 12px var(--modal-icon-color),
                    0 0 14px var(--modal-icon-color);
            }
        }
    }

    .certifications {
        text-align: -webkit-center;
        padding-top: 0px !important;
        .retro {
            font-size: 1.8rem;
        }
        .achievement-text {
            color: white !important;
        }
    }

    .writeups {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        border: 0;
        border-radius: 0;
        padding: 0 !important;

        .writeUpCard {
            box-shadow: 5px 5px 0 0 var(--modal-icon-color);
        }
        .row {
            span {
                width: 100% !important;
            }
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
        }
        .mobImg {
            img {
                transform: translate(0, -15%) !important;
            }
        }

        .imgContainer {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            height: 45%;
            overflow: hidden;
            width: 100%;
            img {
                width: 100%;
            }
        }
        .card-title {
            font-size: 12px;
        }
        .card-text {
            font-size: 10px;
        }
        .writeUpCard {
            // height: 320px;
            border: 0;
            border-radius: 0;
            min-height: 220px;
            border-radius: 10px;
            // box-shadow: 10px 10px 0 0 var(--modal-icon-color);
            transition: 0.5s;
            &:hover {
                // box-shadow: 0 0 0 0;
                .readMoreSpan {
                    visibility: visible;
                }
                .imgContainer {
                    img {
                        // Zoom
                        transition: transform 0.2s;
                        transform: scale(1.1);
                    }
                }
            }
            .readMoreSpan {
                visibility: hidden;
                display: flex;
                place-content: center;
                position: absolute;
                width: 100%;
                left: 0;
                bottom: 0;
                opacity: 0.8;
                border: 0;
                border-radius: 0;
                font-family: "Staatliches";
                line-height: 45px;
                background-color: var(--modal-icon-color);
                color: var(--color-black) !important;
                height: 100%;
                padding: 50%;
                font-size: 50px;
                cursor: pointer;
            }
        }

        .card {
            width: 90%;
            height: fit-content !important;
            margin: 10px;
            .card-body {
                padding: 5px !important;
            }
        }
    }

    .research {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        border: 0;
        border-radius: 0;
        flex-direction: column;
        text-align: -webkit-center;
        padding-top: 0px !important;
        .writeUpCard {
            box-shadow: 5px 5px 0 0 var(--modal-icon-color);
        }
        .row {
            span {
                margin: 0 !important;
                padding: 0 !important;
                width: 100% !important;
            }
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
        }

        .imgContainer {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            height: 150px;
            overflow: hidden;
            width: 100%;
            img {
                width: 100%;
            }
        }

        .researchCard {
            background-color: var(--modal-icon-color);
            height: 320px;
            border: 0;
            border-radius: 0;
            min-height: 320px;
            box-shadow: 5px 5px 0 0 var(--modal-icon-color-hover) !important;
            transition: 0.5s;
            color: white;
            &:hover {
                box-shadow: 0 0 0 0;
                .readMoreSpan {
                    visibility: visible;
                }
                .imgContainer {
                    img {
                        // Zoom
                        transition: transform 0.2s;
                        transform: scale(1.1);
                    }
                }
            }
            .readMoreSpan {
                visibility: hidden;
                display: flex;
                place-content: center;
                position: absolute;
                width: 100%;
                left: 0;
                bottom: 0;
                opacity: 0.8;
                border: 0;
                border-radius: 0;
                font-family: "Staatliches";
                line-height: 45px;
                background-color: var(--modal-icon-color);
                color: var(--color-black) !important;
                height: 100%;
                padding: 50%;
                font-size: 50px;
                cursor: pointer;
            }
        }

        .card {
            width: 100%;
            max-height: 200px !important;
            height: fit-content !important;
            margin: 10px;
            .card-body {
                padding: 5px !important;
            }
            .card-title {
                font-size: 1rem;
                margin-bottom: 0px;
                font-weight: bold;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
            .card-text {
                font-style: italic;
                font-size: 0.8rem;
                margin-bottom: 2px;
            }
            .authorContainer {
                display: flex;
                flex-direction: column;
                .authors {
                    display: flex;
                    flex-wrap: wrap;
                    font-size: 0.8rem;
                    margin: auto;
                    justify-content: center;
                    p {
                        font-size: 0.6rem;
                        margin-left: 7px;
                        border-radius: 10px;
                        padding: 2px 3px;
                        color: var(--modal-icon-color-hover);
                        background-color: var(--color-white) !important;
                        margin-bottom: 5px !important;
                    }
                }
            }
            .authorsName {
                margin-bottom: 0px !important;
            }
            .date {
                position: absolute;
                bottom: 5px;
                left: 50%;
                transform: translate(-50%, 0);
                font-size: 0.7rem;
                color: var(--color-white);
            }
        }
    }
}

.bg-shape-triangles {
    &::before {
        position: absolute;
        bottom: 350px;
        left: -360px;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        z-index: -1;
        transform: rotate(90deg);
        opacity: 0.1;
        content: "";
    }
}

.xLogoTwitter{
    margin: auto;
    margin-left: 10px;
    margin-top: -2px;
    width: auto;
    height: 38px;
}

.pulse-loader {
    height: 64px;
    width: 64px;
    position: relative;
    div {
        position: absolute;
        border-radius: 50%;
        top: 50px;
        left: 50px;
        border: 4px solid white;
        opacity: 0.2;
        border-radius: 50%;
        // animation: pulseAnimate 2s ease-out infinite;
    }

    // div:nth-child(2) {
    //     animation-delay: -1s;
    // }

    :hover {
        div {
            position: absolute;
            border: 4px solid var(--modal-icon-color);
            border-radius: 50%;
            animation: pulseAnimate 2s ease-out infinite;
        }

        // div:nth-child(2) {
        //     animation-delay: -1s;
        // }
    }
}

@keyframes pulseAnimate {
    0% {
        top: 32px;
        left: 32px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 0;
        left: 0;
        width: 64px;
        height: 64px;
        opacity: 0;
    }
}

.modalButtonDiv {
    display: flex;
    width: 550px;
    flex-wrap: wrap;
    flex-direction: row;

    button {
        margin: 5px;
    }
}

.withLove {
    display: flex;
    height: 100vh;
    .vertical-line {
        border-left: 1px solid var(--modal-icon-color);
        height: 25%;
        position: absolute;
        left: 40px;
        bottom: 0;
    }

    .love {
        height: 25%;
        position: relative;
        left: 40px;
        bottom: 350px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        writing-mode: vertical-lr;
        text-orientation: upright;
        color: wheat;
    }

    .loveInBox {
        visibility: hidden;
        position: absolute;
        right: 15px;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid white;
        padding: 10px;
        text-orientation: upright;
        color: wheat;

        width: fit-content;
        line-height: 30px;
        font-weight: 600;
        padding-left: 10px;
        padding-right: 10px;
        background-color: #bababa6e;
    }

    .infoIcon {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 15px;
        color: var(--modal-icon-color);
        cursor: pointer;
        transition: 0.5s;
        &:hover {
            color: var(--modal-icon-color-hover);
            transition: 0.5s;
        }
    }
}

// .marquee {
//     color: white;
// }

.newBtn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    color: var(--color-white);
    border-color: var(--color-white);
    margin-right: 1rem !important;
    text-transform: uppercase !important;

    border-color: #f8f9fa;
    background-color: transparent;
    &:hover {
        color: var(--color-black);
        border-color: #f8f9fa;
        transition: 0.5s;
        background-color: var(--color-white);
        // Blink Effect
        animation: blink 0.2s steps(5, start) 3;
        // Show the button a big bigger
        transform: scale(1.2);
    }
    &:focus {
        box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
    }

    &:focus-visible {
        outline: -webkit-focus-ring-color auto 1px;
    }
    &:active {
        color: #fff;
        background-color: var(--modal-icon-color);
        border-color: #f8f9fa;
    }
}

.mobile_menu_item_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    padding: 10px;
    border-bottom: 1px solid var(--modal-icon-color);
    .mobile_menu_item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 10px;
        border-bottom: 1px solid var(--modal-icon-color);
        .mobile_menu_item_icon {
            width: 10px;
            height: 30px;
            // margin-right: 10px;
        }
        .mobile_menu_item_text {
            // margin-right: 10px;
            padding: 10px;
            font-size: 12px;
            font-weight: 600;
        }
    }
}

.pdf-viewer {
    width: 100%;
    height: 70vh;
    background-color: #f0f0f0; /* Change this to your desired background color */
    border: none; /* Remove the border */
    overflow: auto; /* Add scrollbars if needed */
}

.react-pdf__Page {
    margin: 0 auto; /* Center the page horizontally */
    display: block; /* Ensure proper rendering */
}

.popup-close-icon {
    margin-left: 0px !important;
    background-color: rgba(10, 25, 47, 0.8) !important;
    border-radius: 25px;
}

.download-btn-pdf-viewer {
    position: absolute;
    bottom: 10px;
    left: 10px;
    display: inline-block;
    padding: 5px;
    width: 35px;
    height: 35px;
    background: rgba(10, 25, 47, 0.8);
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    svg {
        margin-left: 0px !important;
    }
}

.hover-text-pdf {
    display: none;
    position: absolute;
    top: 50%;
    left: 40px;
    transform: translateY(-50%);
    white-space: nowrap;
    font-size: 14px;
    color: #fff;
    background-color: rgba(10, 25, 47, 0.8);
    padding: 5px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.download-btn-pdf-viewer:hover .hover-text-pdf {
    display: inline-block;
}

#blogs {
    width: 100vw;
    color: white;

    .blog_container {
        margin-top: 50px;

        .blog_card {
            width: 75%;
            margin-bottom: 20px;
            background-color: var(--modal-icon-color-hover);
            height: fit-content;
            .card__tag {
                // Make it appear at top right
                position: absolute;
                top: 2px;
                right: 5px;
                background-color: #ffffff;
                width: 50px;
                height: 25px;
                color: #0a192f;
                border-radius: 5px;
            }
            .card__content {
                text-align: left;
                padding: 20px;
            }
            &::before {
                position: absolute;
                content: "";
                top: -5px;
                left: -5px;
                width: 100%;
                height: 100%;
                background-color: #ffffff;
                border-radius: 5px;
                opacity: 0.08;
                transition: all 0.5s;
            }
            &::after {
                position: absolute;
                content: "";
                top: -5px;
                left: -5px;
                width: 100%;
                height: 100%;
                background-color: #ffffff;
                border-radius: 5px;
                opacity: 0.08;
                transition: all 0.5s;
            }

            &:hover {
                &::before {
                    content: "";
                    left: 5px;
                    width: 100%;
                    height: 100%;
                    opacity: 0.08;
                    transition: all 0.5s;
                }
                &::after {
                    top: 5px;
                    left: 5px;
                }
            }
        }
    }
}

.backButton {
    width: fit-content;
    button {
        background-color: transparent;
        border: none;
        color: white;
        font-size: 20px;
        cursor: pointer;
        transition: 0.5s;
        margin-top: 10px;
        &:hover {
            color: var(--modal-icon-color-hover);
            transition: 0.5s;
        }
    }
}

@keyframes blink {
    // Make the borders invisible
    0% {
        border-color: #f8f9fa;
    }
    // Make the borders visible
    50% {
        border-color: transparent;
    }
    // Make the borders invisible
    100% {
        border-color: #f8f9fa;
    }
    // to {
    //     visibility: hidden;
    // }
}

.page-not-found {
    width: 100vw !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    height: 100vh !important;
    background-color: #0a192f !important;
    font-family: Arial, sans-serif !important;
    color: #f8f8f8 !important;
}

.page-not-found h1 {
    font-size: 3rem !important;
    margin-bottom: 1rem !important;
}

.page-not-found p {
    font-size: 1.2rem !important;
    text-align: center !important;
}

.back-link {
    margin-top: 1rem;
    color: #f8f8f8;
    text-decoration: none;
    font-weight: bold;
    border: 1px solid #f8f8f8;
    padding: 0.5rem 1rem;
    transition: color 0.3s ease-in-out;
}

.back-link:hover {
    color: #0a192f;
    background-color: #f8f8f8;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
}

.authorsName {
    border-radius: 25px;
    background-color: #ffffff6b;
    padding: 0px 5px 0px 5px;
    margin: 1px;
}
